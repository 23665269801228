// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-all-tags-page-js": () => import("./../src/templates/all-tags-page.js" /* webpackChunkName: "component---src-templates-all-tags-page-js" */),
  "component---src-templates-special-layout-page-js": () => import("./../src/templates/special-layout-page.js" /* webpackChunkName: "component---src-templates-special-layout-page-js" */),
  "component---src-templates-standard-content-js": () => import("./../src/templates/standard-content.js" /* webpackChunkName: "component---src-templates-standard-content-js" */),
  "component---src-templates-tags-js": () => import("./../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-en-js": () => import("./../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-book-thanks-en-js": () => import("./../src/pages/book-thanks.en.js" /* webpackChunkName: "component---src-pages-book-thanks-en-js" */),
  "component---src-pages-purchase-thanks-en-js": () => import("./../src/pages/purchase-thanks.en.js" /* webpackChunkName: "component---src-pages-purchase-thanks-en-js" */),
  "component---src-pages-subscribe-thanks-en-js": () => import("./../src/pages/subscribe-thanks.en.js" /* webpackChunkName: "component---src-pages-subscribe-thanks-en-js" */),
  "component---src-pages-subscribe-thanks-es-js": () => import("./../src/pages/subscribe-thanks.es.js" /* webpackChunkName: "component---src-pages-subscribe-thanks-es-js" */),
  "component---src-pages-subscribe-thanks-fr-js": () => import("./../src/pages/subscribe-thanks.fr.js" /* webpackChunkName: "component---src-pages-subscribe-thanks-fr-js" */),
  "component---src-pages-thanks-js": () => import("./../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-timezone-converter-page-js": () => import("./../src/pages/timezone-converter-page.js" /* webpackChunkName: "component---src-pages-timezone-converter-page-js" */),
  "component---src-pages-timezone-tool-specific-en-js": () => import("./../src/pages/timezone-tool-specific.en.js" /* webpackChunkName: "component---src-pages-timezone-tool-specific-en-js" */),
  "component---src-pages-business-business-michael-gonzalez-rodriguez-1-en-mdjx": () => import("./../src/pages/business/business-Michael-Gonzalez-Rodriguez-1.en.mdjx" /* webpackChunkName: "component---src-pages-business-business-michael-gonzalez-rodriguez-1-en-mdjx" */),
  "component---src-pages-general-hottub-life-1-en-mdjx": () => import("./../src/pages/general/hottub-life-1.en.mdjx" /* webpackChunkName: "component---src-pages-general-hottub-life-1-en-mdjx" */),
  "component---src-pages-reviews-aomais-bluetooth-waterproof-speaker-1-mdjx": () => import("./../src/pages/reviews/aomais bluetooth waterproof speaker-1.mdjx" /* webpackChunkName: "component---src-pages-reviews-aomais-bluetooth-waterproof-speaker-1-mdjx" */),
  "component---src-pages-reference-12-v-line-loss-1-mdjx": () => import("./../src/pages/reference/12V-LineLoss-1.mdjx" /* webpackChunkName: "component---src-pages-reference-12-v-line-loss-1-mdjx" */),
  "component---src-pages-reviews-stiff-joints-1-en-mdjx": () => import("./../src/pages/reviews/stiff-joints-1.en.mdjx" /* webpackChunkName: "component---src-pages-reviews-stiff-joints-1-en-mdjx" */),
  "component---src-pages-reviews-stiff-after-exercise-1-en-mdjx": () => import("./../src/pages/reviews/stiff-after-exercise-1.en.mdjx" /* webpackChunkName: "component---src-pages-reviews-stiff-after-exercise-1-en-mdjx" */),
  "component---src-pages-sample-twinset-1-en-mdjx": () => import("./../src/pages/sample-twinset-1.en.mdjx" /* webpackChunkName: "component---src-pages-sample-twinset-1-en-mdjx" */),
  "component---src-pages-shop-1-en-mdjx": () => import("./../src/pages/shop-1.en.mdjx" /* webpackChunkName: "component---src-pages-shop-1-en-mdjx" */),
  "component---src-pages-shop-2-en-mdjx": () => import("./../src/pages/shop-2.en.mdjx" /* webpackChunkName: "component---src-pages-shop-2-en-mdjx" */),
  "component---src-pages-shop-3-en-mdjx": () => import("./../src/pages/shop-3.en.mdjx" /* webpackChunkName: "component---src-pages-shop-3-en-mdjx" */),
  "component---src-pages-business-business-perfect-software-1-mdjx": () => import("./../src/pages/business/business-perfect-software-1.mdjx" /* webpackChunkName: "component---src-pages-business-business-perfect-software-1-mdjx" */)
}

